import React, { useState, useEffect, useMemo } from 'react';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { calculateTotal } from '../../libs/sum';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const Debt = () => {
  const [debts, setDebts] = useState([]);
  const [showOverlay, setShowOVerlay] = useState('none');
  const [purchaseId, setPurchaseId] = useState(null);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false
  });

  useEffect(() => {
    getDebts();
  }, []);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const getDebts = async () => {
    try {
      var user = AuthService.getCurrentUser();
      const data = await ApiService.getDebts(user.token);
      setDebts(data.debts);
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (files, allFiles) => {
    console.log(acceptedFiles);

    const formData = new FormData();
    formData.append('purchase_id', purchaseId);
    formData.append('file', acceptedFiles[0]);
    var user = AuthService.getCurrentUser();
    const data = await ApiService.uploadVoucher(user.token, formData);

    setShowOVerlay('none');

    Swal.fire({
      icon: 'success',
      title: 'Hecho!',
      text: 'Voucher subido correctamente.'
    });
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div
      className="cart-container"
      style={{ backgroundColor: '#161616 !important' }}
    >
      <section className="section-b-space">
        <div className="container padding-cls">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="col-md-8">
              <div className="checkout-form">Deuda por pagar</div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Deuda</th>
                    <th>Pagado</th>
                    <th width="150">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {debts.map(debt => (
                    <tr key={debt.id}>
                      <td align="right">
                      {calculateTotal(parseFloat(debt.total).toFixed(2), parseFloat(debt.delivery).toFixed(2))}
                      </td>
                      <td align="right">{debt.total_charges}</td>
                      <td align="right">
                        <button
                          onClick={() => {
                            setShowOVerlay('block');
                            setPurchaseId(debt.id);
                          }}
                          className="btn btn-sm btn-outline-success"
                        >
                          Pagar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <div
        id="debt"
        className="search-overlay has-debt"
        style={{ display: showOverlay }}
      >
        <div>
          <span
            className="closebtn"
            onClick={() => {
              setShowOVerlay('none');
            }}
            title="Close Overlay"
          >
            ×
          </span>
          <div className="overlay-content options-overlay-content">
            <div className="container">
              <div>
                <label className="logout-text1"></label>
              </div>
              <div className="row" style={{ display: 'block' }}>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p style={{ color: '#000' }}>
                    Arrastra y suelta algunos archivos aquí
                  </p>
                </div>
                <aside>
                  <h4>Archivos</h4>
                  <ul>{files}</ul>
                </aside>
              </div>
              <div className="row div-logout-button">
                <button
                  style={{ position: 'relative' }}
                  className="btn btn-solid btn-explore mt-10"
                  onClick={handleSubmit}
                >
                  Pagar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debt;
